const $ = require('jquery');
const Swiper = require('swiper');
const { $window } = require('./lib/selectors');

const swiper = new Swiper('.swiper-container', {
  slideClass: 'slideshow__slide',
  wrapperClass: 'slideshow__slides',
  loop: true,
  keyboardControl: true,
  draggable: false,
  autoplay: 5000,
  nextButton: '.slideshow-nav__item--next',
  prevButton: '.slideshow-nav__item--prev',
});
