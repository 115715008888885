const { $document } = require('./lib/selectors');
// include touch library for featherlight
require('hammerjs');
// include featherlight for pop-up images
require('featherlight');
// include gallery feature of featherlight
require('featherlight/src/featherlight.gallery');

initFeatherlight();
$document.ajaxComplete(() => {
  initFeatherlight();
});

function initFeatherlight() {
  if ($('.shadowbox').length) {
    $('.shadowbox').featherlightGallery({
      previousIcon: '<span class="icon-arrow-left"></span>',
      nextIcon: '<span class="icon-arrow-right"></span>',
      closeIcon: '<span title="close" class="icon-cross"></span>',

      beforeOpen: () => {
        $('body').addClass('shadowBoxOpen');
      },

      afterClose: () => {
        $('body').removeClass('shadowBoxOpen');
      },
    });
  }
}
