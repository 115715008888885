require('jq-accordion');
const forEach = require('lodash.foreach');

const allAccordions = document.querySelectorAll('.accordion');

setUpAccordions(allAccordions);

function setUpAccordions(accordions) {
  if (accordions) {
    $('[data-accordion]').accordion();
  }
}
