const $ = require('jquery');
const { $window } = require('./lib/selectors');
const throttle = require('lodash.throttle');
const forEach = require('lodash.foreach');

const imgChangeWidth = 800;
const $hero = $('.js-bg');
// set to null on init
let lastSize = null;

setCorrectImage();
$window.on('resize', throttle(setCorrectImage, 250));

function setCorrectImage() {
  forEach($hero, value => {
    const smImg = value.dataset.bgsmall;
    const lgImg = value.dataset.bglarge;
    // console.log(value);
    // console.log(lgImg);
    // only triggers new background if changing sizes
    if (window.innerWidth < imgChangeWidth && (lastSize > imgChangeWidth || lastSize === null)) {
      $(value).css('background-image', `url(${smImg})`);
      // console.log('hit small');
    } else if (window.innerWidth >= imgChangeWidth && (lastSize <= imgChangeWidth || lastSize === null)) {
      $(value).css('background-image', `url(${lgImg})`);
      // console.log('hit large');
    }
  });
  lastSize = window.innerWidth;
}
