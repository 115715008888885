/* eslint-disable */
// overrides functions from jquery ajax load module
const $ = require('jquery');
const Drupal = require('Drupal');


// this funciton now calls our custom load function instead of regular one
// it also calls the first person automatically
Drupal.behaviors.jquery_ajax_load = {
  attach: function (context, settings) {
    jQuery.ajaxSetup ({
    // Disable caching of AJAX responses
      cache: false
    });

    var trigger = Drupal.settings.jquery_ajax_load.trigger;
    var target = Drupal.settings.jquery_ajax_load.target;
    // Puede ser más de un valor, hay que usar foreach()
    $(trigger).once(function() {
      var html_string = $(this).attr( 'href' );
      // Hay que validar si la ruta trae la URL del sitio
      $(this).attr( 'href' , target );
      var data_target = $(this).attr( 'data-target' );
      if (typeof data_target === 'undefined' ) {
        data_target = target;
      }
      else {
        data_target = '#' + data_target;
      }
      $(this).click(function(evt) {
        evt.preventDefault();
        altos_jquery_ajax_load_load($(this), data_target, html_string, true);
        // console.log($(this));
        // console.log(data_target);
        // console.log(html_string);
      });
    });
    $(trigger).removeClass(trigger);

    // manually load in the team member we want on load
    // this data is set in leadership.js
    if (window.leadershipslider) {
      const nodeToLoad = window.leadershipslider;
      altos_jquery_ajax_load_load($(nodeToLoad.target), nodeToLoad.dataTarget, nodeToLoad.htmlString, false);
    }

  }
};

// modified text and classes on loading animation
// scrolls to content loaded in automatically
function altos_jquery_ajax_load_load(el, target, url, scroll) {
  var module_path = Drupal.settings.jquery_ajax_load.module_path;
  var toggle = Drupal.settings.jquery_ajax_load.toggle;
  var base_path = Drupal.settings.jquery_ajax_load.base_path;
  var animation = Drupal.settings.jquery_ajax_load.animation;
  // scrolls to new content once clicked
  if (scroll === true) {
    setTimeout(function(){
      $('html, body').animate(
      {
        scrollTop: $(target).offset().top - ($('.main-header').height() - 20),
      }, 500);
    }, 1000);
  }

  if( toggle && $(el).hasClass( "jquery_ajax_load_open" ) ) {
    $(el).removeClass( "jquery_ajax_load_open" );
    if ( animation ) {
      $(target).hide('slow', function() {
        $(target).empty();
      });
    }
    else {
      $(target).empty();
    }
  }
  else {
    var loading_html = "<div class='ajax_node_loading paddingAll'>";
    // loading_html += Drupal.t('Loading');
    loading_html += '<img src="/sites/default/files/roedel-loading.gif">';
    // loading_html += module_path;
    // loading_html += '/jquery_ajax_load_loading.gif">';
    loading_html += "</div>";
    $(target).html(loading_html);
    $(target).load(base_path + 'jquery_ajax_load/get' + url, function( response, status, xhr ) {
      if ( status == "error" ) {
        var msg = "Sorry but there was an error: ";
        $(target).html( msg + xhr.status + " " + xhr.statusText );
      }
      else {
        if ( animation ) {
          $(target).hide();
          $(target).show('slow')
        }

      // Drupal.attachBehaviors(target);
      }
    });
    $(el).addClass( "jquery_ajax_load_open" );
  }
}
