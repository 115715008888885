/* eslint-disable */
const throttle = require('lodash.throttle');
const { $window } = require('./lib/selectors');

const roedelHQ = {
  lat: 42.8367025,
  lng: -71.8123972,
};
const iconURL = '/sites/default/files/map-marker.png'
let map;
let markerAdded = false;

if ($('#map').length){
  $window.on('scroll', throttle(checkIfMapOnScreen, 500));
  $window.on('resize', throttle(checkIfMapOnScreen, 500));
}

// init gets called by google on file load
window.initMap = function(){
  map = new google.maps.Map(document.getElementById('map'), {
    zoom: 12,
    center: roedelHQ,
    scrollwheel: false,
  });

  // const marker = new google.maps.Marker({
  //   position: roedelHQ,
  //   map: map,
  //   icon: '/sites/default/files/map-marker.png',
  //   animation: google.maps.Animation.DROP,
  // });

  // keep center of map always center on resize
  google.maps.event.addDomListener(window, "resize", function() {
    var center = map.getCenter();
    google.maps.event.trigger(map, "resize");
    map.setCenter(center);
  });

  // check if we can add marker
  checkIfMapOnScreen();
}

function checkIfMapOnScreen() {
  console.log(isScrolledIntoView($('#map')[0]));
  if(isScrolledIntoView($('#map')[0]) && !(markerAdded)) {
    console.log('should add')
    addMarker();
    markerAdded = true;
  }
}

function addMarker() {
  const marker = new google.maps.Marker({
    position: roedelHQ,
    map: map,
    icon: iconURL,
    animation: google.maps.Animation.DROP,
  });
}

function isScrolledIntoView(el) {
    const boundingRect = el.getBoundingClientRect()
    const elTop = boundingRect.top;
    const elBottom = boundingRect.bottom;
    var isVisible = (elTop >= 0) && (elBottom - (boundingRect.height / 3) <= window.innerHeight);
    return isVisible;
}
