const $ = require('jquery');
const { $body, $window, $document } = require('./lib/selectors');
const TweenMax = require('gsap/src/uncompressed/TweenMax');
const TimelineMax = require('gsap/src/uncompressed/TimelineMax');
const throttle = require('lodash.throttle');

/**
 * Class name to add to the body when the menu is open
 */
const OPEN = 'js-menu-open';
const individualMenuOpen = 'open';
const $hamburger = $('.menu-button');
const $menuWrapper = $('.main-nav');
const $firstLevelNavItems = $('.main-nav .menu-block-wrapper > ul > li');
const $navItemWithChildren = $('.main-nav .menu-block-wrapper > ul > li.is-expanded');
const $logo = $('.logo svg');
// width at which the dekstop menu is show
const menuChange = 1000;

// define opening timeline for menu
const menuIn = new TimelineMax({ paused: true });

// menu animation timeline
menuIn.set($hamburger, {
  onComplete: handleBurger,
})
.to($menuWrapper, 0.3, {
  top: '0vh',
  // onComplete: handleBurger,
  // gsap won't animate back to -100vh when reversing,
  // so set it manually when animation is done
  onReverseComplete: () => {
    $menuWrapper.css('top', '-100vh');
    // $logo is svg so must use attr and not addClass
    $logo.attr('class', '');
    handleBurger();
  },
})
.staggerTo($firstLevelNavItems, 0.2, {
  opacity: 1,
  y: 0,
}, 0.1);
// .to($hamburger, 0.2, {
//   opacity: 1,
// });

// handle clicking on menu button
$hamburger.on('click', evt => {
  evt.preventDefault();
  if ($body.hasClass(OPEN)) {
    // will place reverse animation 2 times as fast
    menuIn.reverse().timeScale(2);
  } else {
    // $logo is svg so must use attr and not addClass
    $logo.attr('class', 'full-color');
    // must reset timescale
    menuIn.play().timeScale(1);
  }
  $body.toggleClass(OPEN);
});

// handle clicking on subnav items
$navItemWithChildren.on('click', evt => {
  // evt.preventDefault();
  const $item = $(evt.currentTarget);

  if ($item.hasClass(individualMenuOpen)) {
    // close if the subnav is already open
    $item.removeClass(individualMenuOpen);
  } else {
    // close any open sub navs
    $navItemWithChildren.removeClass(individualMenuOpen);
    // open the clicked on subnav
    $item.addClass(individualMenuOpen);

    // add a touch handler only whe needed to close menu
    $document.on('touchstart',  e => {
      if (window.innerWidth >= menuChange){
        const container = $item;
        // if the target of the click isn't the container
        // nor a descendant of the container
        if (!container.is(e.currentTarget) && container.has(e.currentTarget).length === 0) {
          $item.removeClass(individualMenuOpen);
          $document.off('.hideMenuClick');
        }
      }
    });
  }
});

// handles removing an open subnav when hover over another menu
// item at desktop
$firstLevelNavItems.on('mouseenter', e => {
  if(window.innerWidth > menuChange) {
    const thisli = $(e.currentTarget);
    const otherli = $('.region-navigation li.is-expanded').not(thisli);
    const othera = otherli.find('a');

    otherli.removeClass(individualMenuOpen);
    othera.blur(); // .removeClass(individualMenuOpen);
  }
});

$firstLevelNavItems.on('mouseleave', e => {
  if(window.innerWidth > menuChange) {
    const thisli = $(e.currentTarget);
    thisli.removeClass(individualMenuOpen);
    // thisli.find('a').removeClass(individualMenuOpen);
  }
});

$window.on('resize', throttle(forceCloseMenu));

function handleBurger() {
  if($hamburger.hasClass('active')) {
    $hamburger.removeClass('active');
  } else {
    $hamburger.addClass('active');
  }
}

// handles closing the menu if desktop size is hit
function forceCloseMenu() {
  if ($body.hasClass(OPEN) && window.innerWidth >= menuChange) {
    // close any open nav items
    $firstLevelNavItems.removeClass(individualMenuOpen);
    // close the menu
    $hamburger.click();
  }
}
