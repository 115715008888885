const $ = require('jquery');
const TweenMax = require('gsap/src/uncompressed/TweenMax');
const forEach = require('lodash.foreach');
const ScrollMagic = require('scrollmagic');
require('scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap');

const controller = new ScrollMagic.Controller();
// giant jquery selector of all element we want to fade in
// doing it this way allows us to easily include/exclude pieces
// without a need to litter the site with classes
const $els = $('.content-aside__content, .view-nodequeue-1, .full-width-content').not('.no-animation');

initScene();

function initScene() {
  // set in foreach since there can be more than one on a page
  forEach($els, element => {
    const $val = $(element);
    const $this = $val[0];

    // moves content up
    const moveText = TweenMax.to($this, 0.5, {
      y: 0,
      opacity: 1,
    });

    new ScrollMagic.Scene({
      triggerElement: $this,
      triggerHook: 0.9,
      reverse: false,
    })
      .setTween(moveText)
      .addTo(controller);
  });
}


// property reference sections
const $properties = $('.referenced-properties .property');

initPropertiesAnimation();

function initPropertiesAnimation() {
  // moves content up
  const waterfallProperties = TweenMax.staggerTo($properties, 0.5, {
    y: 0,
    opacity: 1,
  }, 0.1);

  new ScrollMagic.Scene({
    triggerElement: $properties[0],
    triggerHook: 0.85,
    reverse: false,
  })
    .setTween(waterfallProperties)
    .addTo(controller);
}
