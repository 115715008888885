const $ = require('jquery');
const forEach = require('lodash.foreach');
const Inputmask = require('jquery.inputmask');

const filledClass = 'filled';
const focusClass = 'focused';
const $toMonitor = $('input[type="text"], input[type="tel"], input[type="email"],  textarea');
const $tel = $('input[type="tel"]');
const $selects = $('select');

// add input masking
forEach($tel, field => {
  const im = new Inputmask("(999) 999-9999");
  im.mask(field);
});

// change select color
forEach($selects, field => {
  const defaultVal = $(field).val();
  $(field).change(e => {
    if ($(e.currentTarget).val() !== defaultVal) {
      $(e.currentTarget).addClass(filledClass);
    } else {
      $(e.currentTarget).removeClass(filledClass);
    }
  });
});

// check on page load
forEach($toMonitor, field => {
  checkForFilled(field);
});

// check on blur
$toMonitor.blur(el => {
  checkForFilled(el.currentTarget);
});

$toMonitor.focus(el => {
  $(el.currentTarget).closest('.form-item').addClass(`${filledClass} ${focusClass}`);
});

function checkForFilled(el) {
  const $target = $(el);
  $target.closest('.form-item').removeClass(focusClass);
  if (
    $target.val() !== '' &&
    !$target.closest('.form-item').hasClass(filledClass)
  ) {
    $target.closest('.form-item').addClass(filledClass);
  } else if (
    $target.val() === '' &&
    $target.closest('.form-item').hasClass(filledClass)
  ) {
    $target.closest('.form-item').removeClass(filledClass);
  }
}

// check if we have a response from the google captcha
/* eslint-disable */
$( document ).ready(function() {
  $('form').submit(function(e) {
    var $this = $(e.currentTarget);
    if($this.find('div[id^="google_recaptcha"]').length){
      /* eslint-disable no-undef */
      // var response = grecaptcha.getResponse();
      var response = $this.find('textarea[id^="g-recaptcha-response"]').val();
      // console.log(response);
      if (response.length) {
        // remove error message on good submit
        // to indicate sumbission was valid while page loads
        $this.find('.error--custom').remove();
        return true;
      }
      // remove one if there
      $this.find('.error--custom').remove();
      // only run if false
      $this.find('div[id^="google_recaptcha"]')
        .after('<div class="error error--custom" style="display:block;">Please complete captcha.</div>');
      return false;
    }
  });
});
