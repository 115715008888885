const $ = require('jquery');
const { $document, $window } = require('./lib/selectors');
const throttle = require('lodash.throttle');

const $header = $('.main-header');
const scrolledClass = 'scrolled';

function scrollHeader() {
  if ($document.scrollTop() > 0) {
    $header.addClass(scrolledClass);
  } else {
    $header.removeClass(scrolledClass);
  }
}

// scroll and load handlers
scrollHeader();
$window.on('scroll', throttle(scrollHeader, 250));
