const Swiper = require('swiper');

const swiper = new Swiper('.swiper-container', {
  slideClass: 'paragraphs-item-testimonial',
  wrapperClass: 'paragraphs-items-field-testimonial',
  slidesPerView: 1,
  spaceBetween: 0,
  freeMode: false,
  loop: true,
  speed: 1000,
  keyboardControl: true,
  centeredSlides: true,
  roundLengths: true,
  pagination: '.swiper-pagination',
  paginationClickable: true,
  nextButton: '.testimonials-next',
  prevButton: '.testimonials-prev',
});
