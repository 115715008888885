const $ = require('jquery');
const { $window } = require('./lib/selectors');
const TweenMax = require('gsap/src/uncompressed/TweenMax');
const TimelineMax = require('gsap/src/uncompressed/TimelineMax');
const forEach = require('lodash.foreach');
const throttle = require('lodash.throttle');
const ScrollMagic = require('scrollmagic');
require('scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap');

const controller = new ScrollMagic.Controller();
const $yearSection = $('.paragraphs-item-timeline-year');

initScene();

function initScene() {
  // set in foreach since there can be more than one on a page
  forEach($yearSection, element => {
    // set up vars to use
    // need to reference individual objects in each element, not jquery wrapped object of objects
    const $val = $(element);
    const $this = $val[0];
    const $events = $val.find('.paragraphs-item-timeline-event');
    const yearText = $val.find('.field-name-field-year')[0];
    const moveIn = new TimelineMax();

    // moves content up
    moveIn.from(yearText, 0.5, {
      y: '100%',
      opacity: 0,
      onStart: toggleActiveClass,
      onStartParams: [$val],
      onReverseComplete: toggleActiveClass,
      onReverseCompleteParams: [$val],
    })
    .staggerFrom($events, 0.3, {
      y: '100%',
      opacity: 0,
    }, 0.22, "-=0.25");

    // controls when to fade in years and events
    new ScrollMagic.Scene({
      triggerElement: $this,
      triggerHook: 0.8,
      //reverse: false,
    }).setTween(moveIn)
    .addTo(controller);

  });// end foreach
}

function toggleActiveClass($el) {
  $el.toggleClass('active');
}
