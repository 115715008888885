const $ = require('jquery');
const Swiper = require('swiper');
const { $window } = require('./lib/selectors');
const throttle = require('lodash.throttle');

let slideIndex;

const teamSwiper = new Swiper('.view-leadership-team', {
  slideClass: 'teammember',
  wrapperClass: 'view-content',
  slidesPerView: 'auto',
  spaceBetween: 0,
  // freeMode: true,
  // freeModeSticky: true,
  loop: true,
  loopedSlides: 12,
  freeModeMomentumRatio: 0.5,
  freeModeMomentumVelocityRatio: 0.5,
  speed: 1000,
  keyboardControl: true,
  freeModeMomentumBounce: false,
  centeredSlides: false,
  slideToClickedSlide: true,
  roundLengths: true,
  grabCursor: true,
  nextButton: '.slider-nav--next',
  prevButton: '.slider-nav--prev',
  onSlideChangeStart: swiper => {
    slideIndex = swiper.activeIndex;
  },
});

// on resize, make sure active one stays in the middle
if ($('.view-leadership-team').length) {
  $window.on(
    'resize',
    throttle(resetActiveSlide, 250, {
      leading: true,
      trailing: true,
    })
  );

  // this object will be read by the node-load.js code to load in the first person's profile
  // view rows are duplicated for the slider, so make sure to only grab the first one

  // no longer autoloading bio per design review
  // const personToLoad = $('.views-row-1.teammember a')[0];
  // window.leadershipslider = {
  //   target: personToLoad,
  //   dataTarget: '#load-here',
  //   htmlString: personToLoad.dataset.link,
  // };
}

// handles resetting slide to the active one when resizing
function resetActiveSlide() {
  teamSwiper.slideTo(slideIndex, 0, false);
}



$('.teammember__desc').on('click', (e)=> {
  const url = e.currentTarget.dataset.link;
  ga('send', 'pageview', url);
})
