const $ = require('jquery');
const { $window } = require('./lib/selectors');
const throttle = require('lodash.throttle');

const DESKTOP_BREAKPOINT_WIDTH = 800;

const $wrapper = $('.image-background');
const $items = $('.image-background__content__item');
let $activeItem = null;

// set to null on init
let lastSize = null;
let timer;

$window.on('resize', throttle(setCorrectImage, 250));

// Set default background image, if one is available
if ($wrapper.data('bgsmall') || $wrapper.data('bglarge')) {
  changeImage($wrapper);
}

// only trigger image change is mouse is over for more than 300ms
// prevents crazy image flashing if scrolling or just moving mouse around
$items
  .mouseenter(e => {
    if (!$(e.currentTarget).hasClass('active')) {
      timer = setTimeout(() => {
        const $item = $(e.currentTarget);
        const $dataSource = $item.find('a').first();

        setActiveItem($item);
        changeImage($dataSource);
      }, 300);
    }
  })
  .mouseleave(() => {
    clearTimeout(timer);
  });

/**
 * Updates the active item
 */
function setActiveItem($item) {
  if ($activeItem) {
    $activeItem.removeClass('active');
  }

  $item.addClass('active');
  $activeItem = $item;
}

/**
 * Handles smoothly swapping images
 */
function changeImage($dataSource) {
  const ANIMATION_DURATION = 500;
  const isMobile = window.innerWidth < DESKTOP_BREAKPOINT_WIDTH;

  const newImageUrl = isMobile
    ? $dataSource.data('bgsmall')
    : $dataSource.data('bglarge');

  // Fade out and remove current image
  const $currentImage = $('.js-image-bg');

  $currentImage
    .fadeOut()
    .delay(ANIMATION_DURATION)
    .queue(() => $currentImage.remove());

  // And replace it with the new one
  $('<span class="js-image-bg"></span>')
    .css('background-image', `url(${newImageUrl})`)
    .prependTo($wrapper)
    .hide()
    .fadeIn(ANIMATION_DURATION);
}

/**
 * Swaps out active iamge if the user changes breakpoints
 */
function setCorrectImage() {
  if ($activeItem) {
    const $dataSource = $activeItem.find('a').first();
    const smImg = $dataSource.data('bgsmall');
    const lgImg = $dataSource.data('bglarge');

    // only triggers new background if changing sizes
    if (
      window.innerWidth < DESKTOP_BREAKPOINT_WIDTH &&
      (lastSize > DESKTOP_BREAKPOINT_WIDTH || lastSize === null)
    ) {
      $('.js-image-bg:first-child').css('background-image', `url(${smImg})`);
      // console.log('hit small');
    } else if (
      window.innerWidth >= DESKTOP_BREAKPOINT_WIDTH &&
      (lastSize <= DESKTOP_BREAKPOINT_WIDTH || lastSize === null)
    ) {
      $('.js-image-bg:first-child').css('background-image', `url(${lgImg})`);
      // console.log('hit large');
    }
  }

  lastSize = window.innerWidth;
}
