const $ = require('jquery');

// Make it easier to use jQuery from the console
window.$ = $;

require('./menu.js');
require('./header.js');
require('./responsive-bg.js');
require('./slide-over-text.js');
require('./aside-nav.js');
require('./shadowbox.js');
require('./forms.js');
require('./image-background-list');
require('./gallery.js');
require('./map.js');
require('./hero.js');
require('./node-load.js');
require('./leadership.js');
require('./timeline.js');
require('./testimonial.js');
require('./scroll-to-id.js');
require('./animations.js');
require('./accordion.js');
require('./slideshow.js');
