const $ = require('jquery');
const { $document, $window, $body } = require('./lib/selectors');
const throttle = require('lodash.throttle');
const forEach = require('lodash.foreach');
require('./lib/colorbrightness');

const $sections = $('.field-name-field-sections > .field-items > .field-item');
const $navWrapper = $('.aside-nav');
const navItemClass = 'aside-nav__bar';
// menu items
let menuItems = null;
// page sections
let scrollItems = null;
let lastId = null;

if ($body.hasClass('front')) {
  init();
  $window.on('scroll', throttle(scrollHighlighting, 250));
}

function init() {
  // add data attributes and navigation bars
  forEach($sections, (element, index) => {
    element.dataset.section = index;
    $navWrapper.append(`<span class="${navItemClass}" data-forsection="${index}"></span>`);
  });

  // now everything has its data attribute and elements exist, we can assign the variables
  menuItems = $('.aside-nav__bar');
  scrollItems = menuItems.map(el =>{
    const item = $(`[data-section=${el}]`);
    return item;
  });

    // backgroundcheck library doesn't work with requires, so it is included manually
    // eslint-disable-next-line
    BackgroundCheck.init({
      targets: '.aside-nav__bar',
      images: '.js-bg',
      threshold: 75,
    });

  scrollHighlighting();
}

function scrollHighlighting() {
  // Get container scroll position
  const fromTop = $document.scrollTop() + 200;
  // console.log(fromTop);
  // Get id of current scroll item
  let cur = scrollItems.map(function(){
    if ($(this).offset().top < fromTop)
    return this;
  });
  // Get the id of the current element
  cur = cur[cur.length-1];
  const id = cur && cur.length ? cur[0].dataset.section : "";

  if (lastId !== id) {
    lastId = id;
    // Set/remove active class
    $('.aside-nav__bar').removeClass('highlight').end().find(`[data-forsection=${id}]`).addClass("highlight");
  }

  // eslint-disable-next-line
  BackgroundCheck.refresh();
}

// handle clicking on nav bar and going to that section
$('[data-forsection]').click(event => {
  const clickedID = event.currentTarget.dataset.forsection;
  $('html, body').animate({
        scrollTop: $(`[data-section=${clickedID}]`).offset().top
    }, 1500);
});
