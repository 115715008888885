/**
 * Cached versions of common jQuery selectors
 *
 * Caching jQuery objects can give a performance boost, even with the overhead
 * from CommonJS module resolution.
 *
 * @module lib/selectors
 *
 * @see https://stackoverflow.com/q/291841
 * @see https://stackoverflow.com/q/329151
 */
const $ = require('jquery');

module.exports = {
  /** Wrapped `window` object */
  $window: $(window),

  /** Wrapped `document` object */
  $document: $(document),

  /** Wrapped `<body>` object */
  $body: $(document.body),
};
