const $ = require('jquery');

// Scroll Down on Internal a Link
$('a[href^="#"]').on('click', e => {
  e.preventDefault();
  const $this = $(e.currentTarget);

  if (
    // check to make sure link isn't just a href="#"
    $this.attr('href').length > 1 &&
    // check to make sure link isn't a tab from drupal which uses this href
    $this.attr('href') !== '#undefined'
  ) {
    const $target = $(e.currentTarget.hash);
    const offset = $('.main-header').outerHeight() + 20;
    $('html, body').stop().animate(
      {
        scrollTop: $target.offset().top - offset,
      },
      900,
      'swing'
    );
  }
});
