const $ = require('jquery');
const { $document } = require('./lib/selectors');

// attach when js loads
galleryClickHandler();

// also attach on ajax load
$document.ajaxSuccess(() => {
  galleryClickHandler();
});

function galleryClickHandler() {
  $('.view-all-gallery').click(e => {
    const $target = $(e.currentTarget);
    const $additionalGallery = $target.prev('.gallery-wrapper');
    $target.toggleClass('open');
    $additionalGallery.slideToggle();
  });
}
