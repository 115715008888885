const $ = require('jquery');
const { $window } = require('./lib/selectors');
const TweenMax = require('gsap/src/uncompressed/TweenMax');
const forEach = require('lodash.foreach');
const throttle = require('lodash.throttle');
const ScrollMagic = require('scrollmagic');
require('scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap');

const controller = new ScrollMagic.Controller();
const $els = $('.scroll-text');
let contentHeight;

const breakpoint = 1100;

if ($els.length) {
  controlState();
  initScene();
  $window.on('resize', throttle(controlState, 250));
}

function controlState() {
  contentHeight = $els.find('.scroll-text__content').outerHeight();
  if (window.innerWidth < breakpoint && controller.enabled()) {
    controller.enabled(false);
  } else if (window.innerWidth >= breakpoint && !controller.enabled()) {
    controller.enabled(true);
  }
  controller.update(true);
}

function initScene() {
  // set in foreach since there can be more than one on a page
  forEach($els, element => {
    // set up vars to use
    // need to reference individual objects in each element, not jquery wrapped object of objects
    const $val = $(element);
    const thisEl = element;
    const $content = $val.find('.scroll-text__content')[0];
    const $cover = $val.find('.scroll-text__cover')[0];
    const $background = $val.find('.scroll-text__background')[0];
    // const contentHeight = $val.find('.scroll-text__content').height();

    // moves content up
    $content.style.opacity = 0;
    const moveInText = TweenMax.to($content, 0.5, {
      y: 0,
      opacity: 1,
    });

    // fades bg in when coming in
    const fadeInBackground = TweenMax.to($cover, 0.5, {
      backgroundColor: 'rgba(0,0,0,.5)',
    });

    // fades bg to extra dark as it moves out
    const fadeDark = TweenMax.to($cover, 0.5, {
      backgroundColor: 'rgba(0,0,0,.8)',
    });

    // controls when to fade in background
    new ScrollMagic.Scene({
      triggerElement: thisEl,
      triggerHook: 0.4,
      duration: contentHeight / 2.5,
    })
      .setTween(fadeInBackground)
      .addTo(controller);

    // bring in text
    new ScrollMagic.Scene({
      triggerElement: thisEl,
      triggerHook: 0.4,
      duration: contentHeight / 2.5,
    })
      .setTween(moveInText)
      .addTo(controller);

    // controls when to fade in extra dark background
    new ScrollMagic.Scene({
      triggerElement: thisEl,
      triggerHook: 0,
      offset: contentHeight / 3,
      duration: contentHeight / 2,
    })
      .setTween(fadeDark)
      .addTo(controller);
  });
}
