const $ = require('jquery');

// handle clicking on scroll indicator and going to next section
$('.scroll-indicator').click(event => {
  const $this = $(event.currentTarget);
  $this.addClass('clicked');
  const $nextSibling = $this.closest('.field-item').next();
  $('html, body').animate(
    {
      scrollTop: $nextSibling.offset().top,
    }, 750);
});


$('.hero--full-size, .slideshow').css('height', $(window).height());

$(window).on('resize', () => {
  $('.hero--full-size, .slideshow').css('height', $(window).height());
})
